import { BcInput } from "../../../globalComponents/input/BcInput";
import { Box, Stack, Typography } from "@mui/material";

const Draft = ({ editMode, draft, setDraft, isLoading, isRequired }) => {
  const { fwd, aft } = draft ?? {};

  const draftArr = [
    { draftLabel: "Fwd", draftValue: fwd },
    { draftLabel: "Aft", draftValue: aft },
  ];

  return (
    <Stack
      alignItems="start"
      sx={{
        position: "relative",
        "&::before": {
          content: `"Draft"`,
          fontSize: "12px",
          fontWeight: 600,
          fontFamily: "Inter, sans-serif",
          color: "components.typography.h3.color",
          position: "absolute",
          top: editMode ? "-10px" : "-23px",
          left: "0",
        },
      }}
    >
      <Box display="flex">
        {draftArr.map(({ draftLabel, draftValue }, idx) => (
          <Stack
            key={draftLabel}
            sx={{
              ml: idx && !editMode ? "42px" : editMode && idx ? "10px" : 0,
            }}
          >
            {!editMode ? (
              <Typography variant="body1">{draftLabel}</Typography>
            ) : null}
            {editMode ? (
              <Box mt="23px">
                <BcInput
                    dataAttribute={`draft-${draftLabel.toLowerCase()}`}
                  sx={{
                    textAlign: "end",
                    width: "113px",
                  }}
                  type="text"
                  value={draftValue}
                  appendLabel="m"
                  label={draftLabel}
                  onInput={(value) => {
                    const formattedTargetValue = value
                      .replace(/[^0-9.,]/g, "")
                      .replace(/,/g, ".");

                    setDraft(draftLabel.toLowerCase(), formattedTargetValue);
                  }}
                  isLoading={isLoading}
                  required={isRequired}
                  useAutoStep
                  autocomplete="off"
                />
              </Box>
            ) : (
              <Typography component="span" mt="12px">
                <Typography variant="body2" component="span">
                  {draftValue == null || draftValue === ""
                    ? "Not provided"
                    : draftValue}
                </Typography>
                {draftValue == null || draftValue === "" ? null : (
                  <Typography component="span" variant="body3" ml="16px">
                    m
                  </Typography>
                )}
              </Typography>
            )}
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};

export default Draft;
