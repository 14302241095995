import { BcInput } from "../../../../globalComponents/input/BcInput";
import { useBDNFiguresFieldFunctionality } from "./useBDNFiguresFieldFunctionality";

const BunkersLiftedBDNFiguresField = ({
  bunkerDetails,
  commercialBunkerFactorPreferences,
  setLiftedBunkerProductField,
  principalOrganizationId,
  errors,
}) => {
  const {
    bunkerFactorsModalProps,
    onBlurHandler,
    onClickOnIcon,
    onSaveHandler,
    showIcon,
    setIsOpen,
    isBunkerProductTypeWithImmediateFactorsRequirement,
  } = useBDNFiguresFieldFunctionality({
    bunkerDetails,
    commercialBunkerFactorPreferences,
    resetQuantity: () => setLiftedBunkerProductField("quantity", null),
    updateBunkerFactors: (bunkerFactors) => {
      setLiftedBunkerProductField("bunkerFactors", bunkerFactors);
    },
    principalOrganizationId,
  });

  return (
    <>
      {/*{bunkerFactorsModalProps.isOpen && (*/}
      {/*  <BunkerFactorsModal*/}
      {/*    setModalIsOpen={setIsOpen}*/}
      {/*    callAlsoWhenCanceling={bunkerFactorsModalProps.callAlsoWhenCanceling}*/}
      {/*    onSave={onSaveHandler}*/}
      {/*    initialValues={bunkerFactorsModalProps.initialValues}*/}
      {/*    isBunkerProductTypeWithImmediateFactorsRequirement={*/}
      {/*      isBunkerProductTypeWithImmediateFactorsRequirement*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
      <BcInput
        type="text"
        value={bunkerDetails.quantity}
        appendLabel={"mt"}
        label="BDN Figures"
        onInput={(value) => {
          const formattedTargetValue = value
            .replace(/[^0-9.,]/g, "")
            .replace(/,/g, ".");
          setLiftedBunkerProductField("quantity", formattedTargetValue);
        }}
        // onBlurHandler={onBlurHandler}
        useAutoStep
        autocomplete="off"
        errorText={errors[`liftedBunkerProductsInPort[${bunkerDetails.type}]-quantity`]}
        // iconDetails={
        //   showIcon
        //     ? {
        //         icon: faLeaf,
        //         indicateIconSuccess: bunkerDetails?.bunkerFactors?.every(
        //           (singleBunkerFactor) => singleBunkerFactor.value
        //         ),
        //         onClick: onClickOnIcon,
        //       }
        //     : null
        // }
      />
    </>
  );
};

export default BunkersLiftedBDNFiguresField;
