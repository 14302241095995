import Bunkers from "./Bunkers";


const DraftBunkersFreshWater = ({
  isArrival,
  editMode,
  isLoading,
  bunkers,
  setBunkersFuel,
  freshWater,
  setFreshWater,
  tugs,
  setTugs,
  errorsStateForTugs,
  errorsStateForBunkers,
}) => {
  return (
    <>
      {/*<Draft*/}
      {/*  isArrival={isArrival}*/}
      {/*  isRequired={isRequired}*/}
      {/*  setDraft={setDraft}*/}
      {/*  draft={draft}*/}
      {/*  editMode={editMode}*/}
      {/*  isLoading={isLoading}*/}
      {/*  departurePortName={departurePortName}*/}
      {/*/>*/}
      <Bunkers
        isArrival={isArrival}
        editMode={editMode}
        isLoading={isLoading}
        bunkers={bunkers}
        setBunkersFuel={setBunkersFuel}
        freshWater={freshWater}
        setFreshWater={setFreshWater}
        tugs={tugs}
        setTugs={setTugs}
        errorsStateForTugs={errorsStateForTugs}
        errorsStateForBunkers={errorsStateForBunkers}
      />
    </>
  );
};

export default DraftBunkersFreshWater;
